import axios from "axios"
export class ServicoLinha {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA
  }
  buscarLinhas() {
    return axios({
      url: this.urlServico + `/api/Linha/GetAll`,
      method: "GET"
    })
  }
  salvarLinha(id, nome, status) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    let statusString = status ? "SIM" : "NAO"
    if (id == null) {
      return axios
        .post(this.urlServico + "/api/Linha/Salvar", { id: id, nome: nome, ativo: statusString }, config)
        .catch(error => {
          alert("Erro ao salvar um nova linha " + error)
          return Promise.reject(error)
        })
    } else {
      return axios
        .post(this.urlServico + "/api/Linha/Alterar", { id: id, nome: nome, ativo: statusString }, config)
        .catch(error => {
          alert("Erro ao salvar um nova linha " + error)
          return Promise.reject(error)
        })
    }
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/api/Linha/GetById?id=${id}`,
      method: "GET"
    })
  }
}
